import draggable from "vuedraggable";
import _ from "underscore";
import {getDataByCourseID} from "@/utils/apis";
import ca from "element-ui/src/locale/lang/ca";

export default {
    name: "studentFeatured",
    props: ['nodeIndex', 'chapterIndex'],
    components: {
        draggable
    },
    data() {
        return {
            showResourseList: [],
            showPeitao: [],
            classId: 0,
            //选择的课程id
            select_course_id: this.$route.query.course_id,
            //精选课程
            featuredCourseList: [
                {
                    class: 'courseware',
                    icon: '&#xe677;',
                    src: require('../../../../assets/images/teacher/course/courseware.png'),
                    title: '课件',
                    index: 1,
                    mtype: 0,
                },
                {
                    class: 'textbook',
                    icon: '&#xe687;',
                    src: require('../../../../assets/images/teacher/course/textbook.png'),
                    title: '教材',
                    index: 2,
                    mtype: 0,
                },
                {
                    class: 'micro-lesson',
                    icon: '&#xe688;',
                    src: require('../../../../assets/images/teacher/course/micro-lesson.png'),
                    title: '微课',
                    index: 3,
                    mtype: 0,
                },
                // {
                //     class: 'animation',
                //     icon: '&#xe68e;',
                //     src: require('../../../../assets/images/teacher/course/animation.png'),
                //     title: '教学设计',
                //     index: 4,
                //     mtype: 0,
                // },
                {
                    class: 'person-resource',
                    icon: '&#xe65d;',
                    src: require('../../../../assets/images/teacher/course/person-resource.png'),
                    title: '个人资源',
                    index: 5,
                    mtype: 0,
                },
                {
                    class: 'test-center',
                    icon: '&#xe678;',
                    src: require('../../../../assets/images/teacher/course/practice-train.png'),
                    title: '实操训练',
                    index: 6,
                    mtype: 0,
                },
                {
                    class: 'training-center',
                    icon: '&#xe66f;',
                    src: require('../../../../assets/images/teacher/course/exam-train.png'),
                    title: '考试训练',
                    index: 7,
                    mtype: 0,
                },
            ],
            showCourseList: [
                {
                    class: 'courseware',
                    icon: '&#xe677;',
                    src: require('../../../../assets/images/teacher/course/courseware.png'),
                    title: '课件',
                    index: 1,
                    mtype: 0,
                },
                {
                    class: 'textbook',
                    icon: '&#xe687;',
                    src: require('../../../../assets/images/teacher/course/textbook.png'),
                    title: '教材',
                    index: 2,
                    mtype: 0,
                },
                {
                    class: 'micro-lesson',
                    icon: '&#xe688;',
                    src: require('../../../../assets/images/teacher/course/micro-lesson.png'),
                    title: '微课',
                    index: 3,
                    mtype: 0,
                },
                // {
                //     class: 'animation',
                //     icon: '&#xe68e;',
                //     src: require('../../../../assets/images/teacher/course/animation.png'),
                //     title: '教学设计',
                //     index: 4,
                //     mtype: 0,
                // },
                {
                    class: 'peitao',
                    icon: '&#xe68e;',
                    src: require('../../../../assets/images/teacher/course/peitao.png'),
                    title: '配套材料',
                    index: 5,
                    mtype: 0,
                },
                {
                    class: 'test-center',
                    icon: '&#xe678;',
                    src: require('../../../../assets/images/teacher/course/practice-train.png'),
                    title: '实操训练',
                    index: 6,
                    mtype: 0,
                },
                {
                    class: 'training-center',
                    icon: '&#xe66f;',
                    src: require('../../../../assets/images/teacher/course/exam-train.png'),
                    title: '考试训练',
                    index: 7,
                    mtype: 0,
                },
                {
                    class: 'person-resource',
                    icon: '&#xe65d;',
                    src: require('../../../../assets/images/teacher/course/person-resource.png'),
                    title: '个人资源',
                    index: 8,
                    mtype: 0,
                },
            ],
            //是否显示上传资料弹窗
            dialogUploadMaterial: false,
            //上传资料数据表单
            uploadMaterialForm: {
                select_resources_type: '',
                select_course_class: '',
                title: '',
                resource_file_type: '',
                file: '',
                fileName: '',
                file_path: '',
                pdfFile: '',
                pdfName: '',
                file_preview_path: '',
            },
            uploadMaterialRules: {
                select_resources_type: [
                    {required: true, message: '请选择资源类型', trigger: 'change'}
                ],
                select_course_class: [
                    {required: true, message: '请选择课程分类', trigger: 'change'}
                ],
                file: [
                    {required: true, message: '请上传文件', trigger: 'change'}
                ],
                title: [
                    {required: true, message: '请输入标题', trigger: 'blur'},
                    {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                ],
            },
            formLabelWidth: '100px',
            //课程分类
            adminResourcesClassList: [],
            resourcesClassProps: {
                children: 'children',
                label: 'name',
                value: 'index'
            },
            //资源类型
            adminResourcesTypeList: [],
            //是否要上传PDF 文件
            isUploadPDF: false,
            isPDFInput: false,
            typeList: [],
            newTypeList: [{
                id: 1,
                name: '知识点'
            }, {
                id: 2,
                name: "素材库"
            }, {
                id: 3,
                name: '案例库'
            }, {
                id: 4,
                name: '视频'
            }, {
                id: 5,
                name: '相关文章'
            }],
            newTypePic: [
                {
                    id: 1,
                    src: require('../../../../assets/images/teacher/course/knowledge_point.png')
                }, {
                    id: 2,
                    src: require('../../../../assets/images/teacher/course/sucai.png')
                }, {
                    id: 3,
                    src: require('../../../../assets/images/teacher/course/anli.png')
                }, {
                    id: 4,
                    src: require('../../../../assets/images/teacher/course/video.png')
                }, {
                    id: 5,
                    src: require('../../../../assets/images/teacher/course/related_articles.png')
                }
            ],
            cKejian: [],
            cJiaocai: [],
            cWeike: [],
            cDonghua: [],
            cPeitao: [],
            cJiaoan: [],
            cZhishidian: [],
            cSucaiku: [],
            cAnliku: [],
            cPeiWeike: [],
            cXiangguanwenzhang: [],
            cPersonResource: [],
            testCenter: [],
            practiceCenter: [],
            resourceList: [],
            papersList: [],
            projectsList: [],
            courseContent: [],
            material_list: [],
            course_content: [],
            showList: true,
            saveTemplateShow: false,
            templateName: '',
            selectTemplateList: [],
            tempPoverShow: false,
        }
    },
    mounted() {
        this.courseInfo();
    },
    watch: {
        nodeIndex(d) {
            this.setNull();
        },
        chapterIndex(d) {
            this.setNull();
        },
        $route() {
            this.select_course_id = this.$route.query.course_id
        }
    },
    methods: {
        setNull() {
            let arr = new Array(14).fill({});
            this.$emit('handleList', arr);
            this.courseInfo();
        },
        courseInfo() {
            let param = {
                id: this.select_course_id,
                chapter: Number(this.chapterIndex) + 1,
                node: Number(this.nodeIndex) + 1
            }
            getDataByCourseID(param).then((res) => {
                if (res.code == 200) {
                    this.resourceList = this.getList(res.data, 1);
                    this.papersList = this.getList(res.data, 2);
                    this.projectsList = this.getList(res.data, 3);
                    //课件
                    this.cKejian = this.formatResources(1);
                    // console.log('1222', this.cKejian);
                    //教材
                    this.cJiaocai = this.formatResources(2);
                    // console.log('2', this.cJiaocai);
                    //微课
                    this.cWeike = this.formatResources(3);
                    // console.log('3', this.cWeike);
                    //配套材料
                    this.material_list = this.formatPeitao();
                    //教案
                    // this.cJiaoan = this.formatResources(5);
                    this.cZhishidian = this.formatMaterial(6)//知识点
                    this.cSucaiku = this.formatMaterial(7)//素材库
                    this.cAnliku = this.formatMaterial(8)//案例库
                    this.cPeiWeike = this.formatMaterial(9)//微课
                    this.cXiangguanwenzhang = this.formatMaterial(10)//相关文章
                    this.handleShowPeiTao();

                    this.formatExams();
                    this.formatProject()
                }
            })
        },
        getList(arr, type) {
            let newArr = [];
            newArr = arr.filter(item => {
                return Number(item.type) == Number(type)
            })
            return newArr
        },
        formatExams() {
            _.each(this.papersList, (item) => {
                item.show = 7;
            });
        },
        formatProject() {
            _.each(this.projectsList, (item) => {
                item.show = 6;
            });
        },
        formatPeitao() {
            let reslist = [];
            //格式化资源的属性名，这边是查出所有的资源
            _.each(this.resourceList, (item) => {
                let tmp = {}
                if (Number(item.resource_type_id) > 5) {
                    tmp = item
                    reslist.push(tmp)
                }
            });
            return reslist;
        },
        formatResources(type) {
            let reslist = [];
            //格式化资源的属性名，这边是查出所有的资源
            _.each(this.resourceList, (item) => {
                let tmp = {}
                if (Number(item.resource_type_id) === Number(type)) {
                    tmp = item;
                    tmp.show = type;
                    reslist.push(tmp);
                }
            });
            return reslist;
        },
        formatMaterial(type) {
            let reslist = [];
            //格式化资源的属性名
            _.each(this.material_list, (item) => {
                let tmp = {}
                if (Number(item.resource_type_id) === Number(type)) {
                    tmp = item;
                    switch (item.resource_type_id) {
                        case 6:
                            tmp.icon = 1;
                            break;
                        case 7:
                            tmp.icon = 2;
                            break;
                        case 8:
                            tmp.icon = 3;
                            break;
                        case 9:
                            tmp.icon = 4;
                            break;
                        case 10:
                            tmp.icon = 5;
                            break;
                    }
                    reslist.push(tmp);
                }
            });
            return reslist;
        },
        getResourceList(index) {
            this.showResourseList = [];
            switch (index) {
                case 1:
                    this.showResourseList = this.cKejian;
                    break;
                case 2:
                    this.showResourseList = this.cJiaocai;
                    break;
                case 3:
                    this.showResourseList = this.cWeike;
                    break;
                case 4:
                    this.showResourseList = this.cJiaoan;
                    break;
                case 5:
                    this.$router.push({
                        path: '/student/course/MatingDetail',
                        query: {
                            course_id: this.select_course_id
                        }
                    })
                    break;
                case 6:
                    this.showResourseList = this.projectsList;
                    break;
                case 7:
                    this.showResourseList = this.papersList;
                    break;
            }
        },
        //跳到课件列表页
        goToCourseWare(item) {
            switch (item.type) {
                case 1:
                    let fileType = item.path.split(".")[item.path.split(".").length - 1].toLowerCase()
                    if (fileType === 'pdf' || fileType === 'doc' || fileType === 'docx') {
                        window.open(localStorage.getItem('hosturl') + 'pdf/' + item.id);
                    } else {
                        window.open(item.path, '_blank')
                    }
                    break;
                case 2:
                    this.$emit('changeShow', 2)
                    localStorage.setItem('paperList', JSON.stringify(this.papersList))
                    this.$router.push({
                        path: "/student/course/examDetail",
                        query: {
                            id: item.id,
                            course_id: this.select_course_id,
                            chapter_id: Number(this.chapterIndex) + 1,
                            lesson_id: Number(this.nodeIndex) + 1
                        }
                    })
                    break;
                case 3:
                    switch (item.exam_module_id){
                        // 商品信息采集与处理
                        case 8:
                        case 9:
                        case 10:
                            this.$router.push({
                                path:'/student/trainCenter/goodsInfo/startTraining',
                                query:{
                                    id:item.id,
                                }
                            });
                            break;
                        // 网店管理
                        case 15:
                        case 16:
                        case 18:
                            this.$router.push({
                                path:'/student/trainCenter/startTraining',
                                query:{
                                    id:item.id
                                }
                            });
                            break;
                        // 客服
                        case 21:
                            this.$router.push({
                                path:'/student/trainCenter/startTraining/onlineShopCustomerService',
                                query:{
                                    id:item.id,
                                    course_id: this.select_course_id,
                                    chapter: Number(this.chapterIndex) + 1,
                                    node: Number(this.nodeIndex) + 1
                                }
                            });
                            break;
                        // 数据收集与清洗
                        case 22:
                        case 23:
                            this.$router.push({
                                path:'/student/trainCenter/startTraining/ECommerceDataCollectionAndCleaning',
                                query:{
                                    op_id:item.id,
                                    course_id: this.select_course_id,
                                    chapter: Number(this.chapterIndex) + 1,
                                    node: Number(this.nodeIndex) + 1
                                }
                            });
                            break;
                        // 网店装修
                        case 11:
                        case 12:
                        case 13:
                        case 14:
                            this.$router.push({
                                path:'/student/trainCenter/startTraining/onlineStoreFitment',
                                query:{
                                    id:item.id,
                                    course_id: this.select_course_id,
                                    chapter: Number(this.chapterIndex) + 1,
                                    node: Number(this.nodeIndex) + 1
                                }
                            });
                            break;
                        // 网络推广
                        case 19:
                        case 20:
                            this.$router.push({
                                path:'/student/trainCenter/startTraining/networkPromotion',
                                query:{
                                    id:item.id,
                                    course_id: this.select_course_id,
                                    chapter: Number(this.chapterIndex) + 1,
                                    node: Number(this.nodeIndex) + 1
                                }
                            });
                            break;
                        //网络客户
                        case 42:
                        case 43:
                            this.$router.push({
                                path:'/student/trainCenter/startTraining/cyberCustomerService',
                                query:{
                                    id:item.id,
                                    course_id: this.select_course_id,
                                    chapter: Number(this.chapterIndex) + 1,
                                    node: Number(this.nodeIndex) + 1
                                }
                            });
                            break;
                    }
                    break
            }
        },
        saveCourseCustom() {
            // this.$emit('handleSave', true)
            // console.log(1011,this.module_list);
            // console.log(1012,this.cKejian)

            let list = [];
            if (this.module_list.length) {
                this.module_list.forEach((item, index) => {
                    if (JSON.stringify(item) !== '{}') {
                        if (item.ctype && item.id) {
                            let obj = {
                                type: item.ctype,
                                id: item.id,
                                index: index,
                            }
                            if (item.icon) {
                                obj.oldIndex = item.index;
                            }
                            list.push(obj)
                        } else {
                            let formatList = [];
                            if (item.title === '测试中心') {
                                formatList = this.testCenter
                            } else if (item.title === '实训中心') {
                                formatList = this.practiceCenter
                            } else {
                                formatList = this.formatResources(item.index);
                            }
                            if (formatList.length) {
                                formatList.forEach((item2, index2) => {
                                    let obj2 = {
                                        type: item2.ctype,
                                        id: item2.id,
                                        index: index,
                                    }
                                    if (item.icon) {
                                        obj2.oldIndex = item.index;
                                    }
                                    list.push(obj2)
                                })
                            }
                        }
                    }
                })
            }
            this.selectTemplateList = list;
            if (this.selectTemplateList.length) {
                this.saveTemplateShow = true;
            } else {
                this.$message.warning('请选择至少一个模板！')
            }
        },
        handleTabClick() {

        },
        changeType(id) {
            this.classId = id;
            let arr = [];
            this.material_list.forEach(item => {
                if (id === 0) {
                    arr.push(item)
                } else {
                    if (item.icon === id) {
                        arr.push(item)
                    }
                }
            })
            this.showPeitao = arr;
        },
        handleShowPeiTao() {
            let arr = [];
            let len = this.material_list.length;
            for (let i = 0; i < len; i++) {
                arr.push(this.material_list[i]);
            }
            this.showPeitao = arr;
        },
    }
}
